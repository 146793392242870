const DeleteModal = ({ setShowDeleteModal, handleDeleteGolfCourse }) => {
  return (
    <div className="modal-bg">
      <div className="modal">
        <p>Are you sure you want to delete?</p>
        <div className="modal-btn">
          <button className="btn cancel" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </button>
          <button className="btn delete" onClick={handleDeleteGolfCourse}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
