import { useState, memo, useRef, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from "@react-google-maps/api";
import { addDoc, deleteDoc, doc, onSnapshot, updateDoc } from "@firebase/firestore";
import Search from "./Search";
import { db, golfCoursesCollection } from "../utils/firebase";
import DeleteModal from "./DeleteModal";

// Store google maps config here to avoid re-render
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 21.039301,
  lng: 105.920865,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const libraries = ["places"];

const usersList = {
  ubUdx3Gnj2TmsgqLGYGASBCEZN72: "bicoi99@gmail.com",
  "7Cm9i4sKOTZSg9UGpIeYQC2Lbkt1": "test@gmail.com",
  "7vEwpn0KrYVf8ixKP10FY522bWo1": "hpbdbo9909@gmail.com",
};

const Map = ({ user }) => {
  // States
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchedLocation, setSearchedLocation] = useState({
    lat: 21.039301,
    lng: 105.920865,
    address: "Hoa Sữa 10, Vinhomes Riverside, Phúc Lợi, Long Biên, Hanoi, Vietnam",
    showAdd: false,
  });
  const [golfCourses, setGolfCourses] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Load Google Maps scripts
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // Get map ref
  const mapRef = useRef();
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // Subscribe to golf courses collection from firebase
  useEffect(() => {
    // const unsubscribe = onSnapshot(query(golfCoursesCollection, where("userId", "==", user.uid)), (querySnapshot) => {
    const unsubscribe = onSnapshot(golfCoursesCollection, (querySnapshot) => {
      // Initialise arrays
      let golfCoursesList = [],
        others = [];
      // Append documents to appropriate arrays
      querySnapshot.docs.forEach((doc) => {
        const golfCourse = { id: doc.id, ...doc.data() };
        if (golfCourse.userId === user.uid) golfCoursesList.push(golfCourse);
        else others.push(golfCourse);
      });
      // Merge others to golfCoursesList. If duplicate occurs, create playedBy array containing players who played the course
      others.forEach((other) => {
        if (other.played) {
          let duplicate = golfCoursesList.find(
            (golfCourse) => golfCourse.lat === other.lat && golfCourse.lng === other.lng
          );
          if (duplicate) {
            if (!duplicate.hasOwnProperty("playedBy")) duplicate.playedBy = [duplicate.userId];
            duplicate.playedBy.push(other.userId);
          } else {
            golfCoursesList.push(other);
          }
        }
      });
      // const golfCoursesList = querySnapshot.docs.reduce((prev, curr) => {
      //   const currGolfCourse = { id: curr.id, ...curr.data() };
      //   let duplicate = prev.find(
      //     (golfCourse) => golfCourse.lat === currGolfCourse.lat && golfCourse.lng === currGolfCourse.lng
      //   );
      //   if (duplicate) {
      //     if (!duplicate.hasOwnProperty("players")) duplicate.players = [duplicate.userId];
      //     duplicate.players.push(currGolfCourse.userId);
      //   } else {
      //     prev.push(currGolfCourse);
      //   }
      //   return prev;
      // }, []);
      // console.log(golfCoursesList);
      setGolfCourses(golfCoursesList);
    });
    // Detach listener
    return unsubscribe;
  }, [user.uid]);

  // Pan to call back
  const panTo = useCallback(({ lat, lng, address }) => {
    setSelectedMarker(null);
    mapRef.current.panTo({ lat, lng });
    setSearchedLocation({ lat, lng, address, showAdd: true });
    mapRef.current.setZoom(14);
  }, []);

  // When golf marker is clicked
  const handleMarkerClick = (golfCourse) => {
    // Close search marker
    setSearchedLocation(null);
    // Set new golf course
    setSelectedMarker(golfCourse);
  };

  // Adding new course and save to firestore
  const handleAddGolfCourse = () => {
    // Guard return to ensure no duplicate (same coordinates and same user trying to add)
    if (
      golfCourses.some(
        (golfCourse) =>
          golfCourse.lat === searchedLocation.lat &&
          golfCourse.lng === searchedLocation.lng &&
          golfCourse.userId === user.uid
      )
    ) {
      setSearchedLocation(null);
      return;
    }
    // Add to firestore
    addDoc(golfCoursesCollection, {
      address: searchedLocation.address,
      lat: searchedLocation.lat,
      lng: searchedLocation.lng,
      played: false,
      userId: user.uid,
    })
      .then(() => {
        setSearchedLocation(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete golf course and update firestore
  const handleDeleteGolfCourse = () => {
    deleteDoc(doc(db, "golfCourses", selectedMarker.id))
      .then(() => {
        setSelectedMarker(null);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get played status and update firestore
  const handlePlayedToggle = (e) => {
    updateDoc(doc(db, "golfCourses", selectedMarker.id), {
      played: e.target.checked,
    }).then(() => {
      // Update selectedMarker state after update firestore
      setSelectedMarker({ ...selectedMarker, played: e.target.checked });
    });
  };

  // If load errors
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  // Render main component or Spinner
  return isLoaded ? (
    <>
      {showDeleteModal && (
        <DeleteModal setShowDeleteModal={setShowDeleteModal} handleDeleteGolfCourse={handleDeleteGolfCourse} />
      )}
      <Search panTo={panTo} setSearchedLocation={setSearchedLocation} />
      <GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={center} options={options} onLoad={onLoad}>
        {golfCourses.map((golfCourse) => (
          <Marker
            key={golfCourse.id}
            position={{ lat: golfCourse.lat, lng: golfCourse.lng }}
            icon={{
              url:
                golfCourse.userId !== user.uid
                  ? "/golf-course-grey.svg"
                  : golfCourse.played
                  ? "/golf-course-green.svg"
                  : "/golf-course-red.svg",
              scaledSize: new window.google.maps.Size(40, 40),
              labelOrigin: new window.google.maps.Point(7, 40),
            }}
            onClick={() => handleMarkerClick(golfCourse)}
            label={
              golfCourse.playedBy
                ? {
                    text: golfCourse.playedBy.length.toString(),
                    className: "marker-label",
                    color: "white",
                    fontSize: "12px",
                  }
                : null
            }
          />
        ))}
        {searchedLocation && (
          <InfoWindow
            position={{ lat: searchedLocation.lat, lng: searchedLocation.lng }}
            onCloseClick={() => {
              setSearchedLocation(null);
            }}
            options={{ maxWidth: 300 }}
          >
            <div className="searched-info-window">
              <h1>{searchedLocation.address}</h1>
              <p>
                ({searchedLocation.lat.toFixed(5)}, {searchedLocation.lng.toFixed(5)})
              </p>
              {searchedLocation.showAdd && (
                <button className="btn" onClick={handleAddGolfCourse}>
                  <i className="fas fa-plus"></i> Add Golf Course
                </button>
              )}
            </div>
          </InfoWindow>
        )}
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
          >
            <div className="selected-info-window">
              <h1>{selectedMarker.address}</h1>
              <p>
                ({selectedMarker.lat.toFixed(5)}, {selectedMarker.lng.toFixed(5)})
              </p>
              {selectedMarker.userId !== user.uid ? (
                <p>
                  Played by:{" "}
                  {selectedMarker.playedBy
                    ? selectedMarker.playedBy.map((userId) => usersList[userId]).join(", ")
                    : usersList[selectedMarker.userId]}
                </p>
              ) : (
                <>
                  <div className="played">
                    <p>Played: </p>
                    <label className="switch">
                      <input
                        className={`toggle${selectedMarker.played ? " checked" : ""}`}
                        type="checkbox"
                        onClick={handlePlayedToggle}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  {selectedMarker.playedBy && (
                    <p className="played-by">
                      Also played by:{" "}
                      {selectedMarker.playedBy
                        .reduce((filtered, userId) => {
                          if (userId !== user.uid) filtered.push(usersList[userId]);
                          return filtered;
                        }, [])
                        .join(", ")}
                    </p>
                  )}
                  <button className="btn delete" onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </button>
                </>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) : (
    <div className="loader">
      <img src="./spinner.gif" alt="Loading" />
    </div>
  );
};

export default memo(Map);
