import "./App.css";
import { useEffect, useState } from "react";
import Map from "./components/Map";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "@firebase/auth";
import { auth } from "./utils/firebase";
import UserIcon from "./components/UserIcon";

const App = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        setUser(res.user);
        setError(null);
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/user-not-found":
            setError("Invalid email!");
            break;
          case "auth/wrong-password":
            setError("Invalid password!");
            break;
          default:
            setError("Invalid credentials!");
        }
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => setUser(null))
      .catch((err) => console.log(err));
  };

  return user ? (
    <>
      <Map user={user} />
      <UserIcon user={user} handleLogout={handleLogout} />
    </>
  ) : (
    <div className="login-bg">
      <div className="login">
        <h1>Login</h1>
        <div className="form">
          {error && (
            <div className="error">
              <i className="fas fa-exclamation-circle"></i> {error}
            </div>
          )}
          <div className="form-control">
            <input type="text" required onChange={(e) => setEmail(e.target.value)} />
            <span></span>
            <label>Email</label>
          </div>
          <div className="form-control">
            <input type="password" required onChange={(e) => setPassword(e.target.value)} />
            <span></span>
            <label>Password</label>
          </div>
          <button className="btn" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
