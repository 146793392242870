import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";

const Search = ({ panTo, setSearchedLocation }) => {
  // Use places autocomplete hook
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (address) => {
    // Fill search input with selected value and remove suggestions
    setValue(address, false);
    clearSuggestions();

    // Geocode address and pan to location on map
    getGeocode({ address })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        panTo({ lat, lng, address });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setValue("", false);
    setSearchedLocation(null);
  };

  return (
    <div className="search-container">
      <Combobox onSelect={handleSelect}>
        <div className="search">
          <ComboboxInput value={value} onChange={handleInput} disabled={!ready} placeholder="Search golf courses..." />
          <div className="search-cancel">
            <img src="/close.png" alt="close button" onClick={handleCancel} />
          </div>
        </div>
        {status === "OK" && (
          <ComboboxPopover
            style={{
              maxWidth: 400,
              width: "90%",
              left: "50%",
              transform: "translateX(-50%)",
              margin: "auto",
              top: "4rem",
              borderRadius: 5,
              fontSize: "0.9rem",
              fontFamily: "inherit",
              border: "0.13rem solid black",
            }}
          >
            <ComboboxList>
              {data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  );
};

export default Search;
