import { useState } from "react";

const UserIcon = ({ user, handleLogout }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);

  return (
    <>
      <div className="user-icon" onClick={() => setShowUserInfo(!showUserInfo)}>
        {showUserInfo ? (
          <i className="fas fa-times fa-2x" style={{ marginTop: 1 }}></i>
        ) : (
          <i className="fas fa-user fa-2x"></i>
        )}
      </div>
      {showUserInfo && (
        <div className="user-info">
          <p>{user.email}</p>
          <button className="btn" onClick={handleLogout}>
            Logout
          </button>
        </div>
      )}
    </>
  );
};

export default UserIcon;
